<template>
  <div class="SpeechcraftMark page">
    <div class="top-one">
      <div class="top">
        <div style="width: 260px">
          <a-input-search
            placeholder="请输入要搜索的BotID"
            enter-button
            @search="onSearch"
            v-model:value="bot_id"
          />
        </div>
        <a-cascader
          :options="classify_list"
          placeholder="请选择Bot所属场景"
          style="width: 200px; margin: 0 20px"
          :fieldNames="{
            label: 'classify_name',
            value: 'classify_id',
            children: 'children',
          }"
          @change="handleChanges"
        />
        <a-select
          style="width: 200px"
          @change="handleChange"
          v-model:value="distribution_id"
        >
          <a-select-option value="0">全公司的Bot</a-select-option>
          <a-select-option value="1">我负责的Bot</a-select-option>
          <a-select-option value="2">所属部门及下属部门的Bot</a-select-option>
        </a-select>
        <!-- <a-button type="primary" style="margin-left:20px">
          <template #icon><SearchOutlined /></template>
          搜索
        </a-button> -->
      </div>
      <div>
        <a-button style="margin-right: 15px" type="primary" @click="showDrawer">
          <template #icon><PlusOutlined /></template>
          新建话术
        </a-button>
        <a-dropdown>
          <template #overlay>
            <a-menu @click="handleMenuClick" style="width: 108px">
              <a-menu-item key="1"><ArrowDownOutlined /> 话术导入</a-menu-item>
              <a-menu-item key="2"><ArrowUpOutlined />话术导出</a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary">
            导入导出
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </div>
    </div>
    <a-spin :spinning="spinning" size="large" tip="正在复制...">
      <div class="content" v-if="list.length !== 0">
        <div class="item" v-for="item in list" :key="item.talk_temp_id">
          <div class="title">
            <label>{{ item.talk_temp_name }}</label>
            <span>
              <span class="statuNew" v-if="item.status == '已发布'"></span>
              <span class="statu" v-else></span>
              {{ item.status }}</span
            >
          </div>
          <div class="box" @click="handClick(item)">
            <div>Bot ID: {{ item.talk_temp_id }}</div>
            <div>场景: {{ item.classify_name }}</div>
            <div>拥有者: {{ item.username }}</div>
            <div>
              录音方式: {{ item.voice_method == 1 ? "真人录音" : "合成音" }}
            </div>
            <!-- <div>录音师: {{ item.voice_person }}</div> -->
            <div>更新时间: {{ item.update_time }}</div>
          </div>
          <div class="edit">
            <!-- <div @click="fenpei">分配</div> -->
            <div @click="handleCopy(item.talk_temp_id)">复制</div>
            <div @click="handleDelete(item.talk_temp_id)">删除</div>
          </div>
        </div>
      </div>
      <div class="content" v-else>
        <a-empty :image="simpleImage" style="width: 100%; margin-top: 90px" />
      </div>
    </a-spin>
    <!-- :maskClosable="false" -->
    <a-drawer
      title="新建Bot"
      placement="right"
      v-model:visible="visible"
      :after-visible-change="afterVisibleChange"
      :width="400"
      class="drawer"
    >
      <a-form layout="vertical" ref="formRef" :rules="rules" :model="formState">
        <a-form-item label="Bot名称" name="talk_temp_name">
          <a-input
            placeholder="请输入Bot名称"
            v-model:value="formState.talk_temp_name"
          />
        </a-form-item>
        <a-form-item label="场景">
          <a-radio-group
            v-model:value="formState.industry_enum"
            style="margin-bottom: 10px"
          >
            <a-radio-button value="1">行业场景</a-radio-button>
            <a-radio-button value="2">定制场景</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- v-model:value="formState.aaaa" -->
        <a-form-item label="" name="aaaa">
          <a-cascader
            :options="classify_list"
            placeholder="请选择场景"
            @change="cascaderChange"
            v-model:value="formState.aaaa"
            :fieldNames="{
              label: 'classify_name',
              value: 'classify_id',
              children: 'children',
            }"
          />
        </a-form-item>
        <a-form-item label="声学模型">
          <a-radio-group v-model:value="formState.acoustics_enum">
            <a-radio-button value="1">行业场景</a-radio-button>
            <a-radio-button value="2">定制场景</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="模板">
          <a-radio-group v-model:value="formState.type">
            <a-radio-button value="1">公共模板</a-radio-button>
            <a-radio-button value="2">用自己的模板</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="呼叫类型">
          <a-radio-group v-model:value="formState.call_type">
            <a-radio-button value="1">外呼</a-radio-button>
            <a-radio-button value="2">呼入</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="Bot类别">
          <a-radio-group value="0">
            <a-radio-button value="0">空白模板</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="">
          <a-radio :checked="true">语音Bot</a-radio>
        </a-form-item>
        <a-form-item label="Bot描述" name="detail">
          <a-textarea
            placeholder="请输入Bot描述"
            v-model:value="formState.detail"
          />
        </a-form-item>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="onDrawerOk" :disabled="flag"
          >确定</a-button
        >
      </div>
    </a-drawer>
    <a-modal
      v-model:visible="visibleCopy"
      title="复制话术"
      @ok="handleOkcopy"
      centered
      cancelText="取消"
      okText="确定"
      :maskClosable="false"
      :width="350"
    >
      确认复制？
    </a-modal>
    <a-modal
      v-model:visible="visibleDelete"
      title="删除话术"
      @ok="handleOkDelete"
      centered
      cancelText="取消"
      okText="确定"
      :maskClosable="false"
      :width="350"
    >
      确认删除?
    </a-modal>
    <a-modal
      v-model:visible="daoruVisible"
      title="话术导入"
      centered
      @ok="handleOkDaoru"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="daoru"
      @cancel="handleCancel"
    >
      <a-form>
        <a-form-item label="选择文件" required>
          <a-upload
            :file-list="fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
          >
            <a-button type="primary" :disabled="fileList.length != 0">
              <upload-outlined></upload-outlined>
              上传文件
            </a-button>
          </a-upload>
        </a-form-item>
        <div style="margin: 0px 0px 0px 80px">
          <a href="/static/xls/logy.xlsx">
            <a-button>
              <VerticalAlignBottomOutlined />
              下载模板
            </a-button>
          </a>
        </div>
      </a-form>
      <div style="color: red; margin: 20px 0px 0px 16px">
        注意事项: 1、导入excel请严格按照模板要求，否则可能导致导入失败
      </div>
    </a-modal>
    <!-- <a-modal
      v-model:visible="visibleModel"
      title="分配"
      @ok="handleOk"
      centered
      cancelText="取消"
      okText="确定"
      class="fenpeiModel"
      :maskClosable="false"
    >
      <div class="modelFenpei">
        <div>
          <div class="title-tree">组织架构</div>
          <a-tree
            checkable
            :tree-data="treeData"
            v-model:expandedKeys="expandedKeys"
            v-model:checkedKeys="checkedKeys"
            class="tree-btn"
          >
          </a-tree>
        </div>
        <div>
          <div class="title-tree">已选择</div>
          <div class="tree-btn checked">
            <span>用户：{{ checkList.length }}人</span>
            <div v-for="item in checkList" :key="item.key">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </a-modal> -->
    <a-modal
      v-model:visible="visibleOut"
      title="选择你需要导出的话术"
      centered
      @ok="handleOkOut"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-checkbox-group v-model:value="checkedList">
        <a-checkbox
          v-for="item in list"
          :value="item.talk_temp_id"
          :key="item.talk_temp_id"
          >{{ item.talk_temp_name }}</a-checkbox
        >
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted, ref } from "vue";
import {
  PlusOutlined,
  DownOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons-vue";
import {
  getSpeechList,
  getSpeechAdd,
  getSpeechDelete,
  getSpeechCopy
} from "../../service/SpeechcraftMark.js";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
const options = [
  {
    value: "1",
    label: "Zhejiang",
    children: [
      {
        value: "2",
        label: "Hangzhou",
        children: [
          {
            value: "3",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "4",
    label: "Jiangsu",
    children: [
      {
        value: "5",
        label: "Nanjing",
        children: [
          {
            value: "6",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];
export default {
  name: "SpeechcraftMark",
  components: {
    PlusOutlined,
    DownOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    UploadOutlined,
    VerticalAlignBottomOutlined,
  },
  setup() {
    const rules = {
      talk_temp_name: [
        {
          required: true,
          message: "Bot名称是必填项",
          trigger: "blur",
        },
      ],
      aaaa: [
        {
          type: "array",
          required: true,
          message: "请选择场景",
          trigger: "change",
        },
      ],
    };
    const formRef = ref();
    const formState = reactive({
      talk_temp_name: undefined,
      //   aaaa: ['zhejiang', 'hangzhou', 'xihu'],
      aaaa: [],
      industry_id: undefined,
      detail: undefined,
      industry_enum: "1",
      acoustics_enum: "1",
      type: "1",
      call_type: "1",
      temp_id: "0",
      voice_enum: "1",
    });
    const router = useRouter();
    const state = reactive({
      bot_id: undefined,
      classfiy_id: undefined,
      distribution_id: "0",
      visible: false,
      list: [],
      classify_list: [],
      // visibleModel: false,
      // checkList: [],
      visibleCopy: false,
      visibleDelete: false,
      copyId: undefined,
      deleteId: undefined,
      spinning: false,
      daoruVisible: false,
      visibleOut: false,
      checkedList: [],
      flag: false,
    });
    onMounted(() => {
      //   console.log( state.list)
      init();
    });
    const init = async () => {
      const res = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
        bot_id: state.bot_id,
        classfiy_id: state.classfiy_id,
        distribution_id: state.distribution_id,
      });
      console.log(res);
      state.list = res.data.data.list;
      for (let i = 0; i < state.list.length; i++) {
        state.list[i].classify_name = undefined
        if (state.list[i].status === 1) {
          state.list[i].status = "待发布";
        } else if (state.list[i].status === 2) {
          state.list[i].status = "待录音";
        } else {
          state.list[i].status = "已发布";
        }
      }
      let ifyNames = res.data.data.classify
      for (let y = 0; y < ifyNames.length; y++) {
          for (let x = 0; x < state.list.length; x++) {
        // console.log(ifyNames[y].classify_id,state.list[x].industry_id);
            if(ifyNames[y].classify_id === state.list[x].industry_id){
              state.list[x].classify_name = ifyNames[y].classify_name
            }
          }
      }
      console.log(state.list);
      state.classify_list = res.data.data.classify_list;
    };
    const fileList = ref([]);
    const handleOkDaoru = async () => {};
    const handleCancel = () => {
      fileList.value = [];
    };
    const handleRemove = () => {
      fileList.value = [];
    };
    const beforeUpload = (file) => {
      fileList.value = [file];
      return false;
    };
    const onSearch = () => {
      init();
    };
    const handleChange = () => {
      console.log(state.classfiy_id, state.distribution_id);
      init();
    };
    const handleChanges = (value) => {
      state.classfiy_id = value[value.length - 1];
      //   console.log(state.classfiy_id, state.distribution_id);
      init();
    };
    // const handleOk = () => {};
    const afterVisibleChange = (bool) => {
      console.log("visible", bool);
      if (!bool) {
        console.log(formRef.value);
        formRef.value.resetFields();
      }
    };
    const onClose = () => {
      state.visible = false;
      formRef.value.resetFields();
    };
    const showDrawer = () => {
      state.visible = true;
    };
    // const fenpei = () => {
    //   state.visibleModel = true;
    // };
    // const expandedKeys = ref([]);
    // const selectedKeys = ref(["0-0-0", "0-0-1"]);
    // const checkedKeys = ref([]);
    // watch(expandedKeys, () => {
    //   console.log("expandedKeys", expandedKeys);
    // });
    // watch(selectedKeys, () => {
    //   console.log("selectedKeys", selectedKeys);
    // });
    // watch(checkedKeys, () => {
    //   console.log("checkedKeys", checkedKeys);
    //   state.checkList = [];
    //   function fun(treeData) {
    //     for (let i = 0; i < treeData.length; i++) {
    //       if (!treeData[i].children) {
    //         if (checkedKeys.value.includes(treeData[i].key)) {
    //           state.checkList.push(treeData[i]);
    //         }
    //       } else {
    //         fun(treeData[i].children);
    //       }
    //     }
    //   }
    //   fun(treeData);
    // });
    const handClick = (item) => {
      router.push({
        path: "/huashu",
        query: {
          id: item.talk_temp_id,
          classify_id: item.industry_id,
          weight: item.weight,
        },
      });
    };
    const cascaderChange = (value,label) => {
      // formState.aaaa
      console.log(value);
      formState.industry_id = value[value.length - 1];
      console.log(formState.industry_id);
      console.log(formState);
      console.log(label[1].classify_name);
    };
    const onDrawerOk = async () => {
      const res = await formRef.value.validate();
      if (res) {
        if (formState.talk_temp_name.length > 16) {
          message.warning("Bot名称不能超过16个字符");
          return;
        }
        state.flag = true;
        // console.log(formState);
        const res = await getSpeechAdd({
          access_token: sessionStorage.getItem("token"),
          ...formState,
        });
        console.log(res);

        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.visible = false;
          init();
        } else {
          message.error(res.data.msg);
          state.flag = false;
          return;
        }
      }
      state.flag = false;
    };
    //话术复制
    const handleCopy = (e) => {
      console.log(e);
      state.copyId = e;
      state.visibleCopy = true;
    };
    const handleOkcopy = async () => {
      state.visibleCopy = false;
      state.spinning = true;
      const res = await getSpeechCopy({
        access_token: sessionStorage.getItem("token"),
        speech_id: state.copyId,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }

      setTimeout(() => {
        state.spinning = false;
        init();
      }, 3000);
    };
    const handleDelete = (e) => {
      console.log(e);
      state.deleteId = e;
      state.visibleDelete = true;
    };
    //话术删除
    const handleOkDelete = async () => {
      state.visibleDelete = false;
      const res = await getSpeechDelete({
        access_token: sessionStorage.getItem("token"),
        speech_id: state.deleteId,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
      init();
    };
    const handleMenuClick = (e) => {
      // console.log(e.key);
      switch (e.key) {
        case "1":
          state.daoruVisible = true;
          break;
        case "2":
          state.visibleOut = true;
          break;
      }
    };
    const handleOkOut = () => {};
    // const plainOptions=computed(()=>{
    //     state.list.map(item=>item)
    // })
    return {
      onSearch,
      handleChange,
      ...toRefs(state),
      options,
      afterVisibleChange,
      showDrawer,
      rules,
      formRef,
      formState,
      onClose,
      // fenpei,
      // treeData,
      // expandedKeys,
      // checkedKeys,
      // handleOk,
      handClick,
      cascaderChange,
      onDrawerOk,
      handleChanges,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      handleCopy,
      handleOkcopy,
      handleMenuClick,
      fileList,
      handleOkDaoru,
      handleCancel,
      handleRemove,
      beforeUpload,
      // plainOptions
      handleOkOut,
      handleDelete,
      handleOkDelete,
    };
  },
};
</script>

<style>
.fenpeiModel .ant-modal-body {
  min-height: 400px;
  background-color: #f7f7f7;
}
.drawer .ant-form-item:nth-child(2) {
  margin-bottom: 0;
}
</style>
<style scoped>
.SpeechcraftMark {
  /* padding: 15px; */
  overflow: auto;
}
.top-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 10px solid #f0f2f5;
  padding: 15px;
}
.top {
  display: flex;
}
.content {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.item {
  border: 1px solid #f0f0f0;
  width: 24.2%;
  margin-right: 1%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;
  /* margin-right: 18px; */
  /* margin: 0 7px 20px 7px; */
}
.item:nth-child(4n) {
  margin-right: 0px;
}
.item:hover {
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%);
  cursor: pointer;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}
.title label {
  font-size: 16px;
}
.title span {
  display: flex;
  align-items: center;
}
.box {
  padding: 15px 25px;
  font-size: 14px;
}
.box div {
  margin: 6px 0;
}
.edit {
  display: flex;
  justify-content: space-between;
}
.edit div {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #f0f0f0;
  background-color: #fafafa;
  color: #666;
}
.item .edit div:nth-child(1) {
  /* border-left: 1px solid #f0f0f0; */
  border-right: 1px solid #f0f0f0;
}

.statu {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: orange;
  display: inline-block;
  margin-right: 5px;
  /* line-height: 5px !important; */
}
.statuNew {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #1890ff;
  display: inline-block;
  margin-right: 5px;
  /* line-height: 5px !important; */
}
.modelFenpei {
  display: flex;
  height: 352px;
}
.modelFenpei > div {
  width: 49%;
  background-color: #fff;
}
.modelFenpei div:nth-child(1) {
  margin-right: 2%;
}
.title-tree {
  padding: 6px;
  border-bottom: 2px solid #f7f7f7;
  width: 100% !important;
}
.tree-btn {
  height: calc(100% - 36px);
}
.checked {
  padding: 10px;
}
.checked div {
  border: 1px solid #d9d9d9;
  padding: 1px 12px;
  margin-bottom: 10px;
  background-color: #fbfbfb;
  border-radius: 3px;
}
.ant-checkbox-wrapper {
  min-width: 136px;
}
.ant-checkbox-wrapper:nth-child(1) {
  margin-left: 8px;
}
</style>